.ex-grid-container {
    height: 100%;
    overflow: auto;
    position: relative;
}

.ex-grid{
    display: grid;
}


.ex-grid.header{
    position: sticky;
    top: 0;
    z-index: 1;
}

.ex-grid.header .cell-header{
    padding: 5px;
    background-color: white;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 0.875rem;
}

.ex-grid .cell{
    padding: 5px;
    display: flex;
    align-items: center;
}

.center{
    justify-content: center;
}

.right{
    justify-content: right;
}
/*.ex-grid .command-button{*/
/*    padding: 5px;*/
/*}*/
