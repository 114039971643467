.MuiFormControl-root{
    background-color: white;
}

.field-label {
    margin-bottom: 0.5rem;
    font-weight: bold;
}

.multiselect-container{
    width: 100%;
    overflow-y: auto;
    border: 1px solid rgba(34, 36, 38, 0.15);
}

.multiselect-container{
    padding-bottom: 7px;
    padding-left:  7px;
}

.multiselect-container > div {
    display: flex;
}

.field-multiselect{
    position: relative;
}

.field-multiselect .select-all-none{
    /* position: absolute; */
    top: 5px;
    right: 5px;
}

.vertical-container .MuiGrid-root{
    margin-bottom: 0.7em;
}


input[disabled]:not(.MuiAutocomplete-input), .MuiOutlinedInput-root.disabled, .MuiInputBase-root.Mui-disabled {
    background-color: #eee;
    opacity: 4;
    color: black;
}

.radiobutton-disabled{
    color: #337ab7;
}

/*****************************************************DATE TIME************************************/

.field-date {
    width: 100%;
    display: flex;
}

.field-date  > .MuiFormControl-root{
    flex-basis: 100%;
}


.field-date.time > .MuiFormControl-root{
    flex-basis: 70%;
}

.field-date.time > .input-time{
    flex-basis: 30%;
}

/*****************************************************SELECT************************************/


.field-select.multiselect-options{
    padding: 0.3em 1em;
    border-bottom: 1px solid #e6e7e8;
    display: flex;
    flex-wrap: wrap;
    position: relative;
}

.field-select.multiselect-options > button{
    margin-top: 7px;
    margin-right: 10px;
    font-size: 0.8rem;
    text-transform: none;
}

.field-select.menu-items {
    padding-left: 8px;
    padding-right: 13px;
    padding-top: 5px;
}

.field-select.menu-items > div:first-child > button {
    border-top: 1px solid #e6e7e8;
}

.field-select.menu-items > div > button{
    text-transform: none;
    justify-content: left;
    width: 100%;
    color: black;
    border-bottom: 1px solid #e6e7e8;
    border-left: 1px solid #e6e7e8;
    border-right: 1px solid #e6e7e8;
    border-radius: 0;

}
.field-select.menu-items > div > button.selected {
    background-color: lightblue;
}
    /*****************************************************INPUTS************************************/

input.numeric{
    text-align: right;
}


.MuiFormControl-root.right input{
    text-align: right;
}

.MuiFormControlLabel-root.small-label .MuiFormControlLabel-label{
    font-size: 0.8rem;
}
/*****************************************************ERRORS************************************/

.MuiOutlinedInput-root.error > .MuiOutlinedInput-notchedOutline,
.MuiAutocomplete-root.error fieldset,
.MuiTextField-root.error fieldset,
.MuiTextField-root.error .MuiInput-underline:before,
.radiobutton-list.error
{
    border-color: #a94442 !important;
}

.radiobutton-list.error{
    border-width: 1px;
    border-style: solid;
}

/*****************************************************MULTISELECT************************************/

.multiselect-option{
    display: flex;
    align-items: center;
}

.multiselect-option > label
{
    cursor: pointer;
}

div.MuiAutocomplete-listbox{
    position: relative;
    padding-top: 0px;
}

.MuiAutocomplete-listbox .field-select.multiselect-options{
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
}

