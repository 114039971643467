.modal-container{
    border-radius: 5px ;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 2px 2px 2px black;
}

.modal-container:focus-visible{
    outline: none;
}

.modal-container > div {
    padding: 20px;
}

.modal-container > .header {
    font-size: 1.5rem;
    position: relative;
    box-shadow:  rgb(0 0 0 / 20%) 0 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px
}

.modal-container .close-icon{
    position: absolute;
    top: 10px;
    right: 10px;
    color: #2185D0;
    cursor: pointer;
}

.modal-container .footer{
    background-color: rgb(249, 250, 251);
    border-bottom-left-radius: 5px ;
    border-bottom-right-radius: 5px ;
    border-top: 1px solid rgba(34, 36, 38, 0.15);
    display: flex;
    justify-content: flex-end;
}

.modal-container .footer > button{
    margin-left: 15px;
}
