.loading {
    display: flex;
    justify-content: center;
    height: 90vh;
    align-items: center;
}

.home .MuiSvgIcon-root {
    font-size: 40px;
}

.subtitle {
    font-weight: bold;
    margin-top: 24px;
}

.main-grid {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
    flex-grow: 1;
    height: 1px;
    min-height: 200px;
    margin-top: 20px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-size: 12px !important;
}

.table-detail {
    width: 100%;
    font-size: 12px;
}

.table-detail th, .table-detail td {
    text-align: left;
    border-bottom: 1px solid rgb(224, 224, 224);
    padding: 3px
}

.ag-row-even .ag-cell {
    background-color: rgba(0, 0, 0, 0.04);
}

.ag-header-cell-label .ag-header-cell-text {
    white-space: normal !important;
}

.ag-cell {
    min-height: 35px;
    display: flex !important;
    align-items: center;
}

.trip-grid .ag-cell {
    min-height: 40px;
}

.ag-cell.center {
    justify-content: center;
}

.ag-cell-value {
    line-height: 1.5
}

.ag-cell-value.p-0 {
    padding: 0 !important;
}

.ag-cell-checkbox {
    padding: 8px !important;
}

.full-width .ag-react-container {
    width: 100%;
}

.ag-header-cell-center .ag-header-cell-label {
    justify-content: center;
}

.left {
    text-align: left;
}

.center {
    text-align: center;
}

.right {
    text-align: right;
}

.link-menu {
    text-decoration: none;
    display: flex;
    width: 100%;
    color: inherit;
    align-items: center;
}

.background-red {
    background-color: #ef9a9a !important;
}

.background-yellow {
    background-color: #ffeb3b !important;
}

.status-bar {
    line-height: 1.1;
    font-size: 11px;
    justify-content: center;
}

/*Dialog*/
.swal2-container {
    z-index: 2000 !important;
}

.login-container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1250;
    background-color: white;
}

div.buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.menuIcons .MuiSvgIcon-root {
    font-size: 33px;
}

@media print {
    @page {
        margin: 0 10mm;
    }

    .only-to-print {
        display: inline !important;
    }

    .no-print, .SnackbarContainer-root, .MuiModal-root, .ag-root-wrapper, .ag-theme-alpine > div {
        display: none !important;
    }

    .MuiDialogContent-root {
        padding: 0 !important;
    }

    .MuiDialogContent-dividers {
        padding: 0 !important;
        border: none !important;
    }

    .print-break-page {
        page-break-before: always !important;
    }

    .main-box {
        display: inline !important;
    }
}

.only-to-print {
    display: none;
}

table.trip-documents {
    width: 100%;
    border-collapse: collapse;
    font-size: 11px;
}

.trip-documents td, .rack-report .racks th {
    border: 1px solid black;
    padding: 0 4px;
}

.trip-documents tr:not(.not-center) td {
    text-align: center;
}

.table-custom {
    width: 100%;
}

.table-custom tr:not(.center) th {
    text-align: left;
}

.rack-report .header tr:not(:first-child) td:nth-child(odd) {
    text-align: right;
    padding-right: 10px;
    vertical-align: bottom;
}

.rack-report .header tr:not(:first-child) td:nth-child(even) {
    border-bottom: 1px solid;
    vertical-align: bottom;
}

.main-grid-test {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-bottom: 15px;
    flex-grow: 1;
    height: 1px;
    max-height: 80px;
    /*min-height: 200px;*/
    /*margin-top: 20px;*/
    /*font-family: "Roboto","Helvetica","Arial",sans-serif !important;*/
    /*font-size: 12px !important;*/

}


/*********************************TAB PANEL*****************************************/
.full-tab-panel {
    flex: 1 1 1px;
}

.full-tab-panel > .MuiBox-root {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.MuiPaper-root .MuiTabs-root {
    margin-top: -12px;
}

/*********************************TABLET*****************************************/
.is-tablet .main-grid {
    height: 70vh;
    width: 90vw;
}

@media screen and (max-height: 700px) {
    .is-tablet .main-grid {
        height: 60vh;
        width: 90vw;
    }
}
